
const domain = window.location.hostname
let api = ''
let apiAuth = ''
let logo = 'demo'

if (domain === 'localhost') {
  api = 'https://ibersol.gastroranking.pro/backend/api/v1/gastropricing/'
  apiAuth = 'https://ibersol.gastroranking.pro/backend/api/v1/'
} else {
  api = 'https://' + domain + '/backend/api/v1/gastropricing/'
  apiAuth = 'https://' + domain + '/backend/api/v1/'
  let partsHostname = domain.split('.')
  logo = partsHostname[0]
}

// Obtenemos idioma del usuario
let userLocale = navigator.languages ? navigator.languages[0] : (navigator.language || navigator.userLanguage)
userLocale = (userLocale.startsWith('es-')) ? 'es' : userLocale

let language = ''
switch (userLocale) {
  case 'es':
  case 'ca':
  case 'gl':
  case 'eu':
    language = 'es'
    break
  case 'pt':
    language = 'pt'
    break
  default:
    language = 'en'
}

export default {
  'domain': domain,
  'language': language,
  'api': api,
  'apiAuth': apiAuth,
  'logo': logo
}
