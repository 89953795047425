import appConfig from '@/config-app.js'


// Axios
import axios from 'axios'
axios.defaults.baseURL = appConfig.api
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

export default {  
  getPromotionsItems (brand, filters) {
    let params = {}
    if(filters.province.province_id){
      params.province = filters.province.province_id
    }
    params.country = window.localStorage.getItem('_country_selected')
    
    /* if(filters.channel.id){
      params.channel = filters.channel.id
    }
    if(filters.aggregator.id){
      params.delivery_platform = filters.aggregator.id
    }
    if(filters.family){
      params.family = filters.family.id
    } */
    
    return new Promise((resolve, reject) => {
      axios.get('/promotions/'+ brand + '/',{ params })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
           reject(error.response)
        })
    })
  }, 
  getPromotionsItemModalData (brand, menu_item_id, delivery_platform_id, promotion_label = '', status = '', province = '') {
    let params = {}
    if(province){
      params.province = province.province_id
    }
    if(status !== ''){
      params.promotion_status = status
    }
    params.promotion_label = promotion_label
    params.country = window.localStorage.getItem('_country_selected')
    return new Promise((resolve, reject) => {
      axios.get('/promotion-detail/'+ brand + '/' + delivery_platform_id + '/' + menu_item_id + '/',{ params })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
           reject(error.response)
        })
    })
  },
  getPromotionsItemsFinished (brand, filters) {
    let params = {}
    if(filters.province.province_id){
      params.province = filters.province.province_id
    }
    params.promotion_status = 'finished'
    params.country = window.localStorage.getItem('_country_selected')
    return new Promise((resolve, reject) => {
      axios.get('/promotions/'+ brand + '/',{ params })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
           reject(error.response)
        })
    })
  },
  getPromotionsItemsNews (brand, filters) {
    let params = {}
    if(filters.province.province_id){
      params.province = filters.province.province_id
    }
    params.promotion_status = 'new'
    params.country = window.localStorage.getItem('_country_selected')
    
    return new Promise((resolve, reject) => {
      axios.get('/promotions/'+ brand + '/',{ params })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
           reject(error.response)
        })
    })
  },
}