<template lang="pug">
  div
    gp-filter-top#filtersFixed(@getData="getPromotionsItems" :type="'promotions'")
    v-container.gastroprice-content.with-menu.d-flex.flex-column(fluid)    
      .block-content.row.flex-nowrap
        v-col(v-if="isLoading" cols="12")
          v-skeleton-loader.mr-9(type="table")
        v-col(v-if="!isLoading" cols="12")
          #scrollHorizontal
            .d-flex.mt-2.pb-0            
                .col-item.mr-4(v-if="data.brand")
                  .head.d-flex
                    v-col.d-flex.align-center.border-col-rigth(cols="12")
                .col-item.mr-4(v-if="data.competitors" v-for="itemCompetitors in data.competitors")
                  .head.d-flex
                    v-col.d-flex.align-center.border-col-rigth(cols="12")
          #headBrand
            .d-flex.mt-0.pb-0            
                .col-item.mr-4(v-if="data.brand")
                  .head.d-flex
                    v-col.d-flex.align-center.border-col-rigth(cols="12")
                      img.img-brand.pr-2(:src="data.brand.logo")
                      span.name-brand {{data.brand.name}}                      
                .col-item.mr-4(v-if="data.competitors" v-for="itemCompetitors in data.competitors")
                  .head.d-flex
                    v-col.d-flex.align-center.border-col-rigth(cols="12")
                      img.img-brand.pr-2(:src="itemCompetitors.logo")
                      span.name-brand {{itemCompetitors.name}}
          #body
            .d-flex.mt-0.pb-4
              .col-item.mr-4(v-if="data.brand")              
                .row-content.borderSeparatorPromotions(v-for="(item, index) in data.brand.table")
                  .row-content.d-flex.align-center.headAggregatorPromotions
                    v-col.colImgAggregator(cols="2")
                      img(v-if="item.first_promo" :src="getImageAggregator(item.delivery_platform)")
                    v-col(cols="10" v-if="item.menu_items.length > 0")
                      span.labelPromo #[translate PROMOTION] 
                      span.countPromoRestaurant &nbsp; #[translate In] {{ item.max_centers }} #[translate restaurants]
                      br
                      strong.namePromo
                        span(v-if="item.promo !== ''") "{{ item.promo }}"
                    v-col.withOutPromo(cols="10" v-else)
                      span #[translate No promotions]
                  div(v-if="item.menu_items.length > 0")
                    .row-content.d-flex.align-center.bodyAggregatorPromotions(v-for="(itemMenu, indexMenu) in item.menu_items")
                      v-col.dateMenuItem(cols="2")
                        span {{ itemMenu.star_date }}
                      v-col.nameMenuItem.text-truncate(cols="5")
                        v-tooltip(right color="#fff" nudge-bottom="40" :open-delay="1000" content-class="tooltip-name" transition="slide-x-transition")
                          template(v-slot:activator="{ on, attrs }")
                            span.name.text-truncate(v-bind="attrs" v-on="on") {{ itemMenu.name }}
                          .tooltip
                            span {{ itemMenu.name }}
                            br
                            span {{ itemMenu.description }}
                      v-col.priceMenuItem(cols="3")
                        span {{ itemMenu.price | formatNumber(2,2)}} €
                      v-col.numberCentersMenuItem.pl-0(cols="2")
                        span.cursor-pointer(@click="showDetailsMenuItem(data.brand.id, data.brand.name,itemMenu,item)") {{ itemMenu.n_centers }} #[translate Rest.]

              .col-item.mr-4(v-if="data.competitors" v-for="itemCompetitors in data.competitors")            
                .row-content.borderSeparatorPromotions(v-for="(item, index) in itemCompetitors.table")
                  .row-content.d-flex.align-center.headAggregatorPromotions
                    v-col.colImgAggregator(cols="2")
                      img(:src="getImageAggregator(item.delivery_platform)")
                    v-col(cols="10" v-if="item.menu_items.length > 0")
                      span.labelPromo #[translate PROMOTION] 
                      span.countPromoRestaurant &nbsp; #[translate In] {{ item.max_centers }} #[translate restaurants]
                      br
                      strong.namePromo 
                        span(v-if="item.promo !== ''") "{{ item.promo }}"
                    v-col.withOutPromo(cols="10" v-else)
                      span #[translate No promotions]
                  div(v-if="item.menu_items.length > 0")
                    .row-content.d-flex.align-center.bodyAggregatorPromotions(v-for="(itemMenu, indexMenu) in item.menu_items")
                      v-col.dateMenuItem(cols="2")
                        span {{ itemMenu.star_date }}
                      v-col.nameMenuItem.text-truncate(cols="5")
                        v-tooltip(right color="#fff" nudge-bottom="40" :open-delay="1000" content-class="tooltip-name" transition="slide-x-transition")
                          template(v-slot:activator="{ on, attrs }")
                            span.name.text-truncate(v-bind="attrs" v-on="on") {{ itemMenu.name }}
                          .tooltip
                            span {{ itemMenu.name }}
                            br
                            span {{ itemMenu.description }}
                      v-col.priceMenuItem(cols="3")
                        span {{ itemMenu.price | formatNumber(2,2)}} €
                      v-col.numberCentersMenuItem.pl-0(cols="2")
                        span.cursor-pointer(@click="showDetailsMenuItem(itemCompetitors.id, itemCompetitors.name,itemMenu,item)") {{ itemMenu.n_centers }} #[translate Rest.]
        v-dialog( v-model="dialog"
          hide-overlay
          width="1100")
            v-card
              v-card-text.pb-0
                v-container.pt-12
                  img.close-modal.cursor-pointer(:src="img.close" @click="dialog=false")
                  v-row
                    v-col(cols="12")
                      .row
                        v-col(cols="4")
                          translate.black--text Promotion
                          span.ml-4.primary--text.font-weight-med {{infoModal.namePromotion}}
                        v-col(cols="4")
                          translate.black--text Brand
                          span.ml-4.primary--text.font-weight-med {{infoModal.brand}}
                        v-col(cols="4")
                          translate.black--text Average Price
                          span.ml-4.primary--text.font-weight-med {{ infoModal.price | formatNumber(2,2) }} €
                        v-col(cols="4")                        
                          span.primary--text
                            span {{infoModal.centers}} 
                            translate.black--text restaurants 
                        v-col(cols="4")
                          translate.black--text Aggregator
                          span.ml-4.primary--text.font-weight-med {{infoModal.aggregator}}

              #tableHistorial.scrollYCustom
                div
                  .header.row
                    v-col.border-col-rigth.pt-3.pb-3(cols="3")
                      translate Restaurant
                    v-col.border-col-rigth.pt-3.pb-3(cols="2")
                      translate Direction
                    v-col.border-col-rigth.pt-3.pb-3(cols="2")
                      translate Localidad
                    v-col.border-col-rigth.pt-3.pb-3(cols="2")
                      translate Province
                    v-col.border-col-rigth.pt-3.pb-3
                      translate Code Postal
                    v-col.border-col-rigth.pt-3.pb-3.text-center
                      translate Price
                  div.container.pr-0.pl-0(v-if="dataModal && dataModal.table")
                    .row.border-b-gray(v-for="(row, i) in dataModal.table.rows"  :class="{'bg-ligth-gray': i % 2 != 0}")
                      v-col.align-center.border-col-rigth(cols="3")
                        span {{ row[0] }}
                      v-col.align-center.border-col-rigth(cols="2")
                        span {{ row[1] }}
                      v-col.align-center.border-col-rigth(cols="2")
                        span {{ row[2] }}
                      v-col.align-center.border-col-rigth(cols="2")
                        span {{ row[3] }}
                      v-col.align-center.border-col-rigth.text-center
                        span {{ row[4] }}
                      v-col.align-center.border-col-rigth.text-center
                        strong {{ row[5] | formatNumber(2,2) }} €
</template>
<script>
import GpFilterTop from '@/components/dashboard/filter-top.vue'
import SrvPromotionsAnalysis from '@/services/promotionsAnalysis.js'
import Commons from '@/mixins/commons.js'

export default {
  components: {GpFilterTop},
  name: 'promoComparator',
  mixins: [Commons],
  data() {
    return {
      data: [],
      dataModal: [],
      infoModal: {},
      img:{
        close: require('@/assets/img/plus.png')
      },
      dialog: false
    }
  },
  created(){
    this.$store.dispatch('loadMixPanelEvent')
    if(!this.$store.getters.hasToken) {
      this.$store.dispatch('loadToken')
    }
  
  },
  computed: {
    isLoading(){
      return this.$store.getters.isLoading
    }
  },
  methods: {    
    getPromotionsItems(data){
      this.$store.commit('LOADING', true)
      SrvPromotionsAnalysis.getPromotionsItems(data.brand, data.filters)
      .then(res => {
          if (res.status === 200) {
            this.data = res.data
            this.$store.commit('LOADING', false)
            this.$nextTick(() =>{
              let s1 = document.querySelector('.body-promoComparator #scrollHorizontal');
              let s2 = document.querySelector('.body-promoComparator #headBrand');
              let s3 = document.querySelector('.body-promoComparator #body');
              if(s1 && s2 && s3) {
                s3.style = 'margin-top: ' + (s2.offsetHeight + 8) + 'px !important;'
                s1.addEventListener('scroll', function () {
                    s2.scrollLeft = s1.scrollLeft
                    s3.scrollLeft = s1.scrollLeft
                });
                // s2.addEventListener('scroll', select_scroll_2, false);
                s1.setAttribute('style','width:'+s3.offsetWidth+'px')
                s2.setAttribute('style','width:'+s3.offsetWidth+'px')
              }

            })
          } 
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
    showDetailsMenuItem(brand, brandName, menu, promotion){
      
      this.infoModal.namePromotion = promotion.promo
      this.infoModal.brand = brandName
      this.infoModal.centers = menu.n_centers
      this.infoModal.price = menu.price
      this.infoModal.aggregator = promotion.delivery_platform
      let province = this.$store.getters.hasProvince

      SrvPromotionsAnalysis.getPromotionsItemModalData(brand,menu.id, promotion.delivery_platform_id, promotion.promo,'',province)
        .then(res => {
            if (res.status === 200) {
              this.$store.commit('LOADING', false)
              this.dataModal = res.data
              this.dialog = true
            } 
          }, () => {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          })
    }
  },
  mounted () {
    
    this.$nextTick(() =>{
      let elementBody = document.querySelector('.body-promoComparator .gastroprice-content')
      elementBody.setAttribute('style','padding-top:'+ (document.getElementById('filtersFixed').offsetHeight - 2) + 'px;')
      window.addEventListener('resize', function () {
        let elementBody = document.querySelector('.gastroprice-content')
        elementBody.setAttribute('style','padding-top:'+ (document.getElementById('filtersFixed').offsetHeight - 2) + 'px;')
        let s1 = document.querySelector('.body-promoComparator #scrollHorizontal')
        let s2 = document.querySelector('.body-promoComparator #headBrand')
        let s3 = document.querySelector('.body-promoComparator #body')
        if (s1 && s2 && s3) {
          s1.setAttribute('style','width:'+s3.offsetWidth+'px')
          s2.setAttribute('style','width:'+s3.offsetWidth+'px')
        }
      })
    })
  }
}
</script>
<style lang="scss" scoped>
.tooltip-name{
    color: #000;
    border: 1px solid #0000008c!important;
    max-width: 600px;
}
// scrollbar TOP
#scrollHorizontal{
  position: fixed;
  padding-top:0;
  z-index: 2;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
  scrollbar-color: #498FE1 #e1e1e1;
  .col-item{
    background-color: transparent;
    border:none;
    border-radius: 0;
    height: 0;
  }
    //SCROLL
  &::-webkit-scrollbar {
    width: 6px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #498FE1;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:active {
    background-color: #498FE1;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #498FE1;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-track {
    background: #e1e1e1;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track:hover{
    background: #E6EAF0;
  }
  &::-webkit-scrollbar-track:active {
    background: #E6EAF0;
  }
}
.borderSeparatorPromotions{
  border-bottom:1px solid #707070;
}
.headAggregatorPromotions{
  background-color: #E4E7EB;
  .labelPromo{
    font-size: 10px;
    font-weight: 300;
  }
  .countPromoRestaurant{
    font-size: 11px;
  }
  .namePromo{
    font-size: 12px;
  }
  .col-10{
  border-left: 5px solid #fff;

  }
}
#body{
  overflow: hidden;
}
#headBrand{
  padding-top: 28px;
  background-color: #f5f9ff;
  position: fixed;
  width: 85%;
  display: flex;
  overflow: hidden;  
  margin-top: -2px;
  .mr-4:last-child{
    margin-right: 0 !important;
  }
}
.bodyAggregatorPromotions{
  border-top:1px solid #D9DFE8;
  .dateMenuItem{
    color:#9B9B9B;
    font-size: 10px;
  }
  .nameMenuItem{
    font-size: 12px;
    color:#000;
  }
  .priceMenuItem{
    font-size: 12px;
    text-align: right;
    font-weight: 600;
    color:#000
  }
  .numberCentersMenuItem{
    font-size: 12px;
  }
  .numberCentersMenuItem{
    text-align: right;
    color:var(--v-terciary-base);
  }
}
.colImgAggregator{
}
.withOutPromo{
  font-size: 10px;
  color:#4A4A4A;
}
.col-item{
  width: 366px;
  min-width: 366px;
  background-color: #fff;
  border: 1px solid #D9DFE8;
  border-radius: 5px;
  .head{
    border-bottom: 1px solid #4A4A4A;
    .img-brand{
      max-width: 81px;
      max-height: 40px;
    }
    .name-brand{
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
    }
    .type-family{
      font-size: 14px;
      font-weight: 600;
    }
    .average-text{
      font-size: 12px;
      font-weight: 600;
      color:#CECECE;
    }
  }
  .row-content{
    font-size: 14px;
  }
}

.modal-small-text{
  font-size: 12px;
}
.modal-big-text{
  font-size: 22px;
}
.border-in-modal{
  border: 1px solid #D9DFE8;
  border-radius: 10px;
}
.close-modal{
  position: absolute;
  right: 11px;
  top: 14px;
}
#tableHistorial{
  margin-top:10px;
  border-top: 10px solid #000;
  height: 289px;
  overflow-y: scroll;
  font-size: 14px;
  .header{
    &.row{
      margin:0;
      font-weight: 600;
      border-bottom: 2px solid #000;
    }
  }
  .container{
    padding: 0;
    .row{
      margin: 0;
    }
  }
}
</style>